export class GoogleAddressAutocomplete {
    private readonly autocomplete;
    private readonly streetElement: HTMLInputElement | null;
    private readonly cityElement: HTMLInputElement | null;
    private readonly zipCodeElement: HTMLInputElement | null;

    constructor(streetId: string, cityId: string, zipCodeId: string) {
        this.streetElement = document.getElementById(streetId) as HTMLInputElement;
        this.cityElement = document.getElementById(cityId) as HTMLInputElement;
        this.zipCodeElement = document.getElementById(zipCodeId) as HTMLInputElement;


        if (this.streetElement) {
            this.initAutocomplete();
            this.preventEnterSubmission();
        }
    }

    private async initAutocomplete(): Promise<void> {
        if (!this.streetElement) return;

        if (typeof window.google !== "undefined" && window.google.maps) {
            await (window as any).google.maps.importLibrary("places");

            this.loadGoogleMapsPlacesLibrary().then(() => {
                this.autocomplete = new google.maps.places.Autocomplete(this.streetElement, {
                    types: ["geocode"],
                    componentRestrictions: { country: "DE" },
                });

                this.autocomplete.addListener("place_changed", () => this.fillInAddress());
            });
        } else {
            console.error("Google Maps API not loaded.");
        }
    }

    private async loadGoogleMapsPlacesLibrary(): Promise<void> {
        if (!window.google || !window.google.maps) {
            await (window as any).google.maps.importLibrary("places");
        }
    }

    private preventEnterSubmission(): void {
           this.streetElement?.addEventListener('keydown', (event: KeyboardEvent) => {
               const pacContainer = document.querySelector('.pac-container');

               if (event.key === 'Enter' && pacContainer && pacContainer.offsetParent !== null) {
                   event.preventDefault();
               }
           });
       }

    private fillInAddress(): void {
          const place = this.autocomplete.getPlace();

          if (!place.address_components) {
              console.error("No address components found");
              return;
          }

          let street = "";
          let streetNumber = "";
          let city = "";
          let zipCode = "";

          place.address_components.forEach((component) => {
              const types = component.types;

              if (types.includes("route")) {
                  street = component.long_name;
              } else if (types.includes("street_number")) {
                  streetNumber = component.long_name;
              } else if (types.includes("locality")) {
                  city = component.long_name;
              } else if (types.includes("postal_code")) {
                  zipCode = component.long_name;
              }
          });

          if (this.streetElement) this.streetElement.value = street + ' ' + streetNumber;
          if (this.cityElement) this.cityElement.value = city;
          if (this.zipCodeElement) this.zipCodeElement.value = zipCode;
    }
}
