import Component from 'ShopUi/models/component';

export default class CopyToClipboard extends Component {
    public buttonElement: HTMLButtonElement;

    protected readyCallback(): void {
        this.buttonElement = this.querySelector(`.${this.jsName}-button`);

        this.mapEvents();
    }

    protected mapEvents(): void {
        this.buttonElement.addEventListener('click', (event: Event) => this.onButtonClick(event));
    }

    protected onButtonClick(event: Event) {
        event.preventDefault();

        const temporaryElement = document.createElement('textarea');
        temporaryElement.value = this.string;
        temporaryElement.setAttribute('readonly', '');
        temporaryElement.style.position = 'absolute';
        temporaryElement.style.left = '-9999px';
        document.body.appendChild(temporaryElement);
        temporaryElement.select();
        document.execCommand('copy');
        document.body.removeChild(temporaryElement);
    }

    get string(): string {
        return this.getAttribute('string');
    }
}
