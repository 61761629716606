import Component from 'ShopUi/models/component';

export default class OrderHistoryFilter extends Component {
    uploadLabel: HTMLElement;
    uploadInput: HTMLElement;
    uploadFile: HTMLInputElement;
    fileSizeError: HTMLInputElement;

    protected readyCallback(event?: Event): void {
        this.uploadLabel = <HTMLElement>(
            document.querySelector(this.targetLabel)
        );
        this.uploadInput = <HTMLElement>(
            document.querySelector(this.targetInput)
        );
        this.uploadFile = <HTMLInputElement>(
            document.querySelector(this.targetUploadFile)
        );
        this.fileSizeError = <HTMLInputElement>(
            document.querySelector(this.targetFileSizeError)
        );

        this.mapEvents();
    }

    mapEvents() {
        this.uploadFile.addEventListener('change', (event: Event) => this.validateUploadSize(event));
    }

    validateUploadSize(event: Event): void {
        let maxSizeAsMB = 10,
            fileSize = this.uploadFile.files[0].size,
            errorMessage = this.fileSizeError.value;

        if (this.valueAsMB(fileSize) > maxSizeAsMB) {
            this.uploadFile.value = '';
            alert(errorMessage);
        }
    }

    valueAsMB(value) {
        return value/1024/1024;
    }

    get targetLabel() {
        return this.getAttribute('label');
    }

    get targetInput() {
        return this.getAttribute('input');
    }

    get targetUploadFile() {
        return this.getAttribute('uploadFile');
    }

    get targetFileSizeError() {
        return this.getAttribute('fileSizeError');
    }

    get hiddenClass() {
        return this.getAttribute('hiddenClass');
    }
}
