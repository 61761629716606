export class GoogleAddressValidator {
    private apiKey: string;
    private missingStreetNumberErrorMessage: string;
    private mismatchingCityAndZipCodeErrorMessage: string;
    private invalidZipCodeErrorMessage: string;
    private invalidCityErrorMessage: string;
    private invalidStreetErrorMessage: string;

    constructor(apiKey: string) {
        this.apiKey = apiKey;
        this.missingStreetNumberErrorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-missing-street-number-error-message');
        this.mismatchingCityAndZipCodeErrorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-mismatching-city-and-zip-error-message');
        this.invalidZipCodeErrorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-invalid-zip-error-message');
        this.invalidCityErrorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-invalid-city-error-message');
        this.invalidStreetErrorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-invalid-street-error-message');
    }

    async validateAddress(street: string, city: string, zip: string, formId: string): Promise<boolean> {
        const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${this.apiKey}`;
        let validationErrorElement = document.querySelector('[class=addressForm_' + formId + '_validation_error]') as HTMLElement;

        if (!city) {
            validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' + this.invalidCityErrorMessage;
        }

        if (!zip) {
            validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' +this.invalidZipCodeErrorMessage;
        }

        if (!street) {
            validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' +this.invalidStreetErrorMessage;
        }

        if (!street || !zip || !city) {
            return false
        }

        const requestBody = {
            address: {
                regionCode: 'DE',
                addressLines: [street],
                locality: city,
                postalCode: zip,
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();

            const addressComponents = data.result.address.addressComponents;
            const hasMissingComponents = data.result.address.missingComponentTypes ? true : false;
            const unconfirmedComponentTypes = data.result.address.unconfirmedComponentTypes ? data.result.address.unconfirmedComponentTypes : [];

            let isValid = true;

            if (hasMissingComponents === true) {
                validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' + this.missingStreetNumberErrorMessage;
            }

            if (unconfirmedComponentTypes.includes('locality')) {
                validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' + this.mismatchingCityAndZipCodeErrorMessage;
            }

            if (unconfirmedComponentTypes.includes('postal_code')) {
                validationErrorElement.innerHTML = validationErrorElement.innerHTML + '<br/> - ' + this.invalidZipCodeErrorMessage;
            }

            addressComponents.forEach(addressComponent => {
                if (addressComponent.confirmationLevel !== 'CONFIRMED' && addressComponent.componentType != "street_number") {
                    isValid = false;
                }
            });

            return isValid && !hasMissingComponents;
        } catch (error) {
            console.error("Google Address Validation Error:", error);
            return false;
        }
    }
}
