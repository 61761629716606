import Component from 'ShopUi/models/component'
import { GoogleAddressValidator } from './google-address-validation';
import { GoogleAddressAutocomplete } from './google-address-autocomplete';

export default class CheckoutAddress extends Component {
    inputFields: HTMLInputElement[];
    form: HTMLFormElement;
    googleAddressValidator: GoogleAddressValidator;
    newShippingAddress: string = 'newShippingAddress';
    endCustomerAddress: string = 'endCustomerAddress';
    germanCountryCode: string = 'DE';
    errorMessage: string;

    constructor() {
        super();
        this.inputFields = <HTMLInputElement[]>Array.from(document.getElementsByClassName('input--expand'));
        this.form = document.querySelector('form[name=addressesForm]');
        this.errorMessage = document.querySelector('[id=newShippingAddress]').getAttribute('data-error-message');
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.inputFields.forEach((input: HTMLInputElement) => input.addEventListener('blur', (event: Event) => this.onBlur(input)));
    }

    protected newAddress(): Boolean {
        let valuesArray = [];
        this.inputFields.forEach((input:HTMLInputElement) => {
            input.type == 'text' && input.required && !!input.value ? valuesArray.push(input.value) : null;
        });
        return !!valuesArray.length;
    }

    protected onBlur(input): void {
        this.newAddress() ?
            !input.value ?
            input.required ?
                input.classList.add('input--error')
                :
                null
            :
            input.classList.remove('input--error')
        :
        this.inputFields.forEach((input:HTMLInputElement) => {
            input.classList.remove('input--error')
        });
    }

    private init(): void {
        if (this.form) {
            this.form.addEventListener('submit', this.handleSubmit.bind(this));
        }

        const addressForms = [
            this.newShippingAddress,
            this.endCustomerAddress,
        ];

        addressForms.forEach((formId) => {
            new GoogleAddressAutocomplete(
                `addressesForm_${formId}_address1`,
                `addressesForm_${formId}_city`,
                `addressesForm_${formId}_zip_code`,
            );
        });
    }

    private async handleSubmit(event: Event): Promise<void> {
        let newShippingAddressCountry = (document.querySelector('[id=addressesForm_' + this.newShippingAddress + '_iso2_code]') as HTMLInputElement).value;
        let checkoutForm = (event.target as HTMLFormElement);

        if (newShippingAddressCountry !== this.germanCountryCode || (!window?.SPRYKER_CONFIG)) {
            checkoutForm.submit();

            return
        }

        event.preventDefault();

        const apiKey = (window as any).SPRYKER_CONFIG.GOOGLE_MAPS_API_KEY;
        this.googleAddressValidator = new GoogleAddressValidator(apiKey);

        let newCustomerAddressIsValid = await this.validateAddress(this.newShippingAddress);

        let endCustomerAddressForm = document.querySelector('[id=' + this.endCustomerAddress + ']') as HTMLElement;
        let endCustomerAddressCountry = (document.querySelector('[id=addressesForm_' + this.endCustomerAddress + '_iso2_code]') as HTMLInputElement).value;
        let endCustomerAddressIsValid = true;

        if (endCustomerAddressCountry === this.germanCountryCode && endCustomerAddressForm.classList.contains('form--hidden') !== true) {
            endCustomerAddressIsValid = await this.validateAddress(this.endCustomerAddress);
        }

        if (newCustomerAddressIsValid && endCustomerAddressIsValid) {
            checkoutForm.submit();
        }

        return;
    }

    private async validateAddress(shippingAddressType: string): Promise<bool> {
        let street = (document.querySelector('[id=addressesForm_' + shippingAddressType + '_address1]') as HTMLInputElement).value;
        let zipCode = (document.querySelector('[id=addressesForm_' + shippingAddressType + '_zip_code]') as HTMLInputElement).value;
        let city = (document.querySelector('[id=addressesForm_' + shippingAddressType + '_city]') as HTMLInputElement).value;
        let validationError = document.querySelector('[class=addressForm_' + shippingAddressType + '_validation_error]') as HTMLElement;

        validationError.innerHTML = "";

        let addressIsValid = true;
        if (street || zipCode || city) {
            addressIsValid = await this.googleAddressValidator.validateAddress(
                street,
                city,
                zipCode,
                shippingAddressType,
            );
        }

        if (!addressIsValid) {
            validationError.innerHTML = this.errorMessage + validationError.innerHTML;
            validationError.style.display = "block";

            return false;
        } else {
            validationError.style.display = "none";
        }

        return addressIsValid;
    }
}
