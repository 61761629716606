import Component from 'ShopUi/models/component';

export default class TwoFactorModal extends Component {

    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {}

    protected init(): void {
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.onModalClose();
    }

    protected onModalClose() {
        const modalWindow = document.querySelector('modal-window');

        modalWindow.addEventListener('closed', function(event) {
            window.location.href = '/login/2fa/cancel';
        });
    }
}
