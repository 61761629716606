import './navigation-list';


let acc = document.getElementsByClassName("title--footer--js");
let i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        this.parentElement.classList.toggle("is-active");
        this.classList.toggle("is-active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight){
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
}
