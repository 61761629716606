import Component from 'ShopUi/models/component';
import DynamicNotificationArea, { EVENT_UPDATE_DYNAMIC_MESSAGES } from 'ShopUi/components/organisms/dynamic-notification-area/dynamic-notification-area';
import { EVENT_UPDATE_TOTAL_PRICE } from 'src/ShopUi/components/molecules/price-total-calculator/price-total-calculator';

interface LivePriceResponseInterface {
    isHighlighted: boolean;
    isAvailable: boolean;
    message: string | null;
    netPrice: number | null;
    netPriceFormatted: string | null;
}

export default class PriceFetcher extends Component {
    private dynamicNotificationArea: DynamicNotificationArea;
    private quantityElement: HTMLSpanElement;
    private quantityInput: HTMLInputElement;
    private priceElement: HTMLElement;
    private priceAmount: HTMLElement;
    private priceOriginalAmount: HTMLElement;
    private priceTotalCalculator: HTMLElement;
    private isRealtimePriceRequestedInput: HTMLInputElement;
    private isLoading: boolean = false;
    private hasQuantityChanged: boolean = false;
    private onPlpGridPage: HTMLElement;

    constructor() {
        super();
        this.dynamicNotificationArea = <DynamicNotificationArea>document.querySelector('dynamic-notification-area');

        this.priceElement = <HTMLElement>document.querySelector(`.js-volume-price, .js-price[data-sku="${this.sku}"]`);
        this.priceAmount = this.priceElement.querySelector(`.js-volume-price__price, .js-price__price`);
        this.priceOriginalAmount = this.priceElement.querySelector(`.js-volume-price__original, .js-price__original`);

        this.onPlpGridPage = document.querySelector<HTMLInputElement>(`.price--plp-grid`);

        if (!this.onPlpGridPage) {
            this.isRealtimePriceRequestedInput = <HTMLInputElement>(document.querySelector(`.js-add-to-cart__is-realtime-price-requested[data-sku="${this.sku}"]`));
            this.quantityInput = <HTMLInputElement> document.querySelector(`[data-price-fetcher-quantity="${this.sku}"]`);
            this.quantityElement = <HTMLSpanElement>(this.querySelector(`.${this.jsName}__quantity`));
            this.priceTotalCalculator = <HTMLElement>document.querySelector(`.js-price-total-calculator[sku="${this.sku}"]`);
        }
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    private mapEvents() {
        window.addEventListener('load', () => this.updatePricesAndView());
        if (!this.onPlpGridPage) {
            ['input', 'change'].forEach((event) => {
                this.quantityInput.addEventListener(event, (event: Event) => this.onQuantityChange(event));
            });
        }
    }

    private async updatePricesAndView() {
        this.toggleIsLoading();

        try {
            const livePriceResponse = await this.fetchLivePrice();
            this.updateQuantityAndPrices(livePriceResponse);
            this.updateView(livePriceResponse);
            this.dispatchUpdateDynamicMessagesEvent(livePriceResponse);
            this.dispatchUpdateTotalPriceEvent(livePriceResponse);
        } catch (error) {
            throw new Error(error);
        } finally {
            this.toggleIsLoading();
        }
    }

    private onQuantityChange(event: Event) {
        if (event.type === 'change' && event.isTrusted) {
            return;
        }

        // @ts-ignore
        this.quantityInput.value = event.target.value;
        this.hasQuantityChanged = true;

        this.updatePricesAndView();
    }

    private toggleIsLoading(): void {
        const isLoadingCssClass = `${this.name}--is-loading`;
        this.isLoading = !this.isLoading;

        this.classList.toggle(isLoadingCssClass);
        if (!this.onPlpGridPage) {
            this.priceTotalCalculator.style.opacity = this.isLoading ? '0' : '1';
        }
        document.body.style.pointerEvents = this.isLoading ? 'none' : '';
    }

    private fetchLivePrice(): Promise<LivePriceResponseInterface> {
        let quantityInput: number | string;

        if (this.onPlpGridPage) {
            quantityInput = 1;
        } else {
            quantityInput = this.quantityInput.value;
        }

        return fetch('/realtime-price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sku: this.sku,
                quantity: quantityInput,
                originalPrice: this.originalPrice,
                buttonProjectRequest: this.buttonProjectRequest
            })
        }).then(response => response.json());
    }

    private updateQuantityAndPrices(livePriceResponse: LivePriceResponseInterface) {

        this.priceAmount.innerHTML = livePriceResponse.netPriceFormatted;
        this.priceOriginalAmount.innerHTML = livePriceResponse.isHighlighted ? this.originalPriceFormatted : '';
        if (!this.onPlpGridPage) {
            this.quantityElement.innerHTML = this.quantityInput.value;
            this.isRealtimePriceRequestedInput.value = livePriceResponse.isAvailable ? '1' : '0';
        }

    }

    private updateView(livePriceResponse: LivePriceResponseInterface): void {
        this.priceAmount.classList.remove('price__amount--hide', 'volume-price__price--hide');
        this.priceOriginalAmount.classList.remove('price__amount--hide');
        this.classList.toggle(`${this.name}--new-price-applied`, livePriceResponse.isHighlighted);
        this.priceAmount.classList.toggle('price__amount--green', livePriceResponse.isHighlighted);
        if (!this.onPlpGridPage) {
            this.priceTotalCalculator.style.display = Number(this.quantityInput.value) > 1 ? 'block' : 'none';
        }
    }

    private dispatchUpdateDynamicMessagesEvent(livePriceResponse: LivePriceResponseInterface): void {
        const shouldDispatchEvent = !livePriceResponse.isAvailable && this.hasQuantityChanged;
        const dynamicNotificationCustomEvent = new CustomEvent(EVENT_UPDATE_DYNAMIC_MESSAGES, {
            detail: livePriceResponse.message
        });

        if (shouldDispatchEvent || (!this.hasQuantityChanged && this.dynamicNotificationArea.children.length === 0)) {
            document.dispatchEvent(dynamicNotificationCustomEvent);
        }
    }

    private dispatchUpdateTotalPriceEvent(livePriceResponse: LivePriceResponseInterface) {
        this.dispatchEvent(new CustomEvent(EVENT_UPDATE_TOTAL_PRICE, { detail: livePriceResponse.netPrice }));
    }

    private get sku(): string {
        return this.getAttribute('sku');
    }

    private get originalPrice(): string {
        return this.getAttribute('original-price');
    }

    private get originalPriceFormatted(): string {
        return this.getAttribute('original-price-formatted');
    }

    private get buttonProjectRequest(): string {
        return this.getAttribute('button-project-request');
    }
}
