interface ProductDataInterface {
    itemId: string;
    itemName: string;
    unitPrice: number;
    quantity: number;
    currency: 'EUR';
}

declare global {
    interface Window {
        dataLayer: any;
    }
}

class GoogleTagManager {
    public async addToCart(productData: ProductDataInterface) {
        if (!GoogleTagManager.checkDataLayerExists())
            return;

        if (
            !productData.itemId ||
            !productData.itemName ||
            !productData.unitPrice ||
            !productData.quantity
        )
            return;

        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            'event': 'add_to_cart',
            'ecommerce': {
                'currency': productData.currency,
                'value': (productData.unitPrice * productData.quantity).toFixed(2),
                'items': {
                    'item_id': productData.itemId,
                    'item_name': productData.itemName,
                    'quantity': productData.quantity,
                    'price': productData.unitPrice
                }
            }
        });
    };

    public async removeFromCart(productData: ProductDataInterface) {
        if (!GoogleTagManager.checkDataLayerExists())
            return;

        if (
            !productData.itemId ||
            !productData.itemName ||
            !productData.unitPrice ||
            !productData.quantity
        )
            return;

        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            'event': 'remove_from_cart',
            'ecommerce': {
                'currency': productData.currency,
                'value': (productData.unitPrice * productData.quantity).toFixed(2),
                'items': {
                    'item_id': productData.itemId,
                    'item_name': productData.itemName,
                    'quantity': productData.quantity,
                    'price': productData.unitPrice
                }
            }
        });
    };

    private static checkDataLayerExists(): boolean {
        return window.hasOwnProperty('dataLayer');
    };
}

export default new GoogleTagManager();
