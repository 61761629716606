import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import GoogleTagManager from '../../../../../../GoogleTagManager/Theme/default/components/molecules/google-tag-manager';

export default class AddToCart extends Component {
    ajaxProvider: AjaxProvider
    addToCartButton: HTMLButtonElement
    quantityInput: HTMLInputElement

    private disabled: boolean
    private isRealtimePriceRequestedInput: HTMLInputElement;

    constructor() {
        super();

        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.addToCartButton = <HTMLButtonElement> this.querySelector(`.${this.jsName}__button`);
        this.quantityInput = <HTMLInputElement> this.querySelector(`.${this.jsName}__quantity`);
        this.isRealtimePriceRequestedInput = <HTMLInputElement> this.querySelector(`.${this.jsName}__is-realtime-price-requested`);

        this.disabled = this.addToCartButton.disabled;
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.addToCartButton.addEventListener('click', (event: Event) => this.onButtonClick(event));
        this.quantityInput.addEventListener('keydown', (event: KeyboardEvent) => this.onQuantityChange(event));
    }

    protected onButtonClick(event: Event) {
        this.trackAddProductToCart();

        if (this.submitForm) {
            this.addToCartButton.form.submit();
            return;
        }

        event.preventDefault();

        this.toggleDisableOnAddToCartElements();

        this.addToCart().then(() => {
            this.toggleDisableOnAddToCartElements();

            // @TODO: this is because response contains HTML, the best would be to redirect by using response from cart
            // controller.
            window.location.href = this.redirectUrl;
        });
    }

    protected async addToCart(): Promise<string> {
        const requestData = new FormData();
        requestData.append('quantity', this.quantityValue.toString());

        if (this.isRealtimePriceRequestedInput) {
            requestData.append('isRealtimePriceRequested', this.isRealtimePriceRequestedInput.value);
        }

        return this.ajaxProvider.fetch(requestData);
    }

    protected toggleDisableOnAddToCartElements(): void {
        this.disabled = !this.disabled;
        this.addToCartButton.disabled = this.disabled;

        if (this.quantityInput)
            this.quantityInput.disabled = this.disabled;
    }

    get redirectUrl(): string {
        return this.getAttribute('redirect-url');
    }

    get submitForm(): boolean {
        return Boolean(this.getAttribute('submit-form'));
    }

    get productSku(): string {
        return this.getAttribute('data-sku');
    }

    get productName(): string {
        return this.getAttribute('data-name');
    }

    get productPrice(): number {
        let price = this.getAttribute('data-price');

        return AddToCart.convertIntToFloat(price);
    }

    get quantityValue(): number {
        let qtyValue = parseInt(this.quantityInput.value.trim());

        return Number(qtyValue);
    }

    private static convertIntToFloat(intString: string): number {
        return parseFloat(intString.slice(0, -2) + '.' + intString.slice(-2));
    }

    private trackAddProductToCart() {
        if (
            !this.productSku ||
            !this.productName ||
            !this.productPrice ||
            !this.quantityValue
        )
            return;

        GoogleTagManager.addToCart({
            itemId: this.productSku,
            itemName: this.productName,
            unitPrice: this.productPrice,
            quantity: this.quantityValue,
            currency: 'EUR'
        });
    }

    private onQuantityChange(event: KeyboardEvent) {
        const intRegExp = /\d/;

        // @ts-ignore
        if (
            (event.key.length > 1) || ((event.key === '-') &&
            (event.target.value.length === 0)) || intRegExp.test(event.key)
        ) {
            return;
        }

        event.preventDefault();
    }
}
