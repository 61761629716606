import Component from 'ShopUi/models/component';

export default class AddToShoppingListLink extends Component {
    private addToCartElement: HTMLElement;

    private quantityInput: HTMLInputElement;

    private addToShoppingListLink: HTMLLinkElement;

    constructor() {
        super();
        this.addToCartElement = <HTMLElement>document.querySelector(`.js-add-to-cart[data-sku="${this.sku}"]`);
        this.quantityInput = <HTMLInputElement>this.addToCartElement.querySelector(`.js-add-to-cart__quantity`);
        this.addToShoppingListLink = <HTMLLinkElement>this.querySelector('a');
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    private mapEvents() {
        this.quantityInput.addEventListener('input', (event: Event) => this.onQuantityChange(event));
    }

    private onQuantityChange(event: Event) {
        // @ts-ignore
        this.addToShoppingListLink.setAttribute('href', this.changeUrl(event.target.value || 1))
    }

    private changeUrl(quantity) {
        let url = new URL(this.addToShoppingListLink.getAttribute('href'));
        url.searchParams.set('quantity', quantity);
        return url.toString();
    }

    private get sku(): string {
        return this.getAttribute('sku');
    }
}
