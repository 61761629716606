import AddToCart from "../../../../../../ShopUi/Theme/default/components/molecules/add-to-cart/add-to-cart";

export default class PackagingUnitAddToCartPdp extends AddToCart {
    multiCart: HTMLSelectElement
    selectMeasurementUnit: HTMLSelectElement
    inputMeasurementUnit: HTMLInputElement

    constructor() {
        super();

        this.multiCart = <HTMLSelectElement>this.querySelector(`.${this.jsName}__multi-cart-widget select`)
        this.selectMeasurementUnit = <HTMLSelectElement>this.querySelector(`.${this.jsName}__measurement-unit select`)
        this.inputMeasurementUnit = <HTMLInputElement>this.querySelector(`.${this.jsName}__measurement-unit input`)
    }

    protected async addToCart(): Promise<void> {
        const requestData = new FormData()
        requestData.append('quantity', this.quantityValue.toString())

        if(this.multiCart !== null) {
            requestData.append(this.multiCart.name, this.multiCart.value.trim())
        }
        if(this.selectMeasurementUnit !== null) {
            requestData.append(this.selectMeasurementUnit.name, this.selectMeasurementUnit.value.trim())
        }
        if(this.inputMeasurementUnit !== null) {
            requestData.append(this.inputMeasurementUnit.name, this.inputMeasurementUnit.value.trim())
        }

        return await this.ajaxProvider.fetch(requestData)
    }
}
