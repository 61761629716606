import Component from 'ShopUi/models/component';

export default class ModalWindow extends Component {
    triggerButtons: HTMLElement[];

    toggleButton: HTMLElement;

    closeButtons: HTMLElement[];

    overlay: HTMLElement;

    focusableElements: HTMLElement[];

    firstFocusableEl: HTMLElement;

    lastFocusableEl: HTMLElement;

    focusedElBeforeOpen: HTMLElement;

    randomModalId: string;

    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
    }

    protected init(): void {
        if (this.triggerSelector)
            this.triggerButtons = <HTMLElement[]> Array.from(
                document.querySelectorAll(
                    this.triggerSelector
                )
            );

        this.closeButtons = Array.from(this.querySelectorAll(this.getAttribute('data-close-trigger')));
        this.focusableElements = Array.from(this.querySelectorAll(this.focusableElementsSelector));
        this.firstFocusableEl = this.focusableElements[0];
        this.lastFocusableEl = this.focusableElements[this.focusableElements.length - 1];
        this.setRandomModalId();
        this.createOverlay();

        document.body.insertBefore(this, this.overlay);

        this.mapEvents();
    }

    protected mapEvents(): void {
        this.registerOpeningTrigger();
        this.registerClosingTriggers();
        this.registerKeyDownEvent();
    }

    private registerOpeningTrigger(): void {
        if (this.shouldOpen) {
            this.openModal();
        }

        if (this.triggerButtons)
            this.triggerButtons.forEach((triggerButton) => {
                triggerButton.addEventListener('click', this.openModal, false);
            });
    }

    private registerClosingTriggers(): void {
        if (!this.closingByTriggerIsPossible())
            return;

        if (this.closeButtons)
            this.closeButtons.forEach((button) => {
                button.addEventListener('click', (event: Event) => this.closeModal(event));
            });

        this.overlay.addEventListener('click', (event: Event) => this.closeModal(event));
    }

    private registerKeyDownEvent(): void {
        this.addEventListener('keydown', (event: KeyboardEvent) => this.onTriggerKeydown(event));
    }

    private openModal = () => {
        /* eslint-disable no-invalid-this */
        this.dispatchOpenEvent();
        this.focusedElBeforeOpen = <HTMLElement>document.activeElement;
        this.classList.add(`${this.name}__active`);
        this.overlay.classList.add(`${this.name}__active`);
        if (this.firstFocusableEl)
            this.firstFocusableEl.focus();

        document.body.style.overflow = 'hidden';
        this.dispatchOpenedEvent();
    }

    private closeModal(event: Event): void {
        event.preventDefault();
        this.removeAttribute('data-open');
        this.classList.remove(`${this.name}__active`);
        this.overlay.classList.remove(`${this.name}__active`);
        this.blur();
        if (this.focusedElBeforeOpen)
            this.focusedElBeforeOpen.focus();

        document.body.style.overflow = null;
        this.dispatchClosedEvent();
    }

    protected onTriggerKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.closingByTriggerIsPossible())
            this.closeModal(event);

        if (event.key === 'Tab')
            if (this.focusableElements.length === 1) {
                event.preventDefault();
            } else if (event.shiftKey) {
                this.handleBackwardTab(event);
            } else {
                this.handleForwardTab(event);
            }
    }

    private dispatchOpenEvent() {
        const event = new Event('open');
        this.dispatchEvent(event);
    }

    private dispatchOpenedEvent() {
        const event = new Event('opened');
        this.dispatchEvent(event);
    }

    private dispatchClosedEvent() {
        const event = new Event('closed');
        this.dispatchEvent(event);
    }

    private handleBackwardTab(event: Event) {
        if (document.activeElement === this.firstFocusableEl) {
            event.preventDefault();
            this.lastFocusableEl.focus();
        }
    }

    private handleForwardTab(event: Event) {
        if (document.activeElement === this.lastFocusableEl) {
            event.preventDefault();
            this.firstFocusableEl.focus();
        }
    }

    private createOverlay(): void {
        this.overlay = document.createElement('div');
        this.overlay.setAttribute('tabindex', '-1');
        this.overlay.setAttribute('id', `${this.randomModalId}__overlay`);
        this.overlay.classList.add(`${this.name}__overlay`);

        if (this.shouldBlurBackground()) {
            this.overlay.classList.add(`${this.name}__overlay_blur`);
        }

        document.body.appendChild(this.overlay);
    }

    private shouldBlurBackground(): boolean {
        return this.getAttribute('data-blur-background') === '1';
    }

    private closingByTriggerIsPossible(): boolean {
        return this.getAttribute('data-disallow-closing') !== '1';
    }

    private setRandomModalId() {
        const randomNumber = Math.floor(Math.random() * 1000000);
        this.randomModalId = `${this.name}__${randomNumber}`;
    }

    get triggerSelector() {
        const triggerSelector = this.getAttribute('data-trigger');
        return triggerSelector !== null && triggerSelector !== '' ? triggerSelector : null;
    }

    get shouldOpen() {
        return this.getAttribute('data-open') === '1';
    }

    // eslint-disable-next-line class-methods-use-this
    get focusableElementsSelector() {
        return 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), ' +
            'button:not([disabled]), [tabindex="0"]';
    }
}
