import Component from 'ShopUi/models/component';

export const EVENT_UPDATE_TOTAL_PRICE = 'updateTotalPrice';

export default class PriceTotalCalculator extends Component {
    private quantityInput: HTMLInputElement;
    private priceFetcher: HTMLElement;
    private price: number;

    constructor() {
        super();

        this.quantityInput = <HTMLInputElement> document.querySelector(`[data-price-fetcher-quantity="${this.sku}"]`);
        this.priceFetcher = <HTMLElement> document.querySelector(`price-fetcher[sku="${this.sku}"]`);
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    protected init(): void {
        this.mapEvents();
    }

    private mapEvents() {
        this.quantityInput?.addEventListener('input', (event: Event) => this.onQuantityChange(event));
        this.priceFetcher?.addEventListener(EVENT_UPDATE_TOTAL_PRICE, (event: CustomEvent) => this.onPriceChange(event));
    }

    private onQuantityChange(event: Event) {
        // @ts-ignore
        this.updatePriceTotal(event.target.value * (Number(this.priceInitial)));

        if (this.isInitiallyHidden) {
            this.classList.add('is-hidden');
        }
    }

    private onPriceChange(event: CustomEvent) {
        if (this.isInitiallyHidden) {
            this.classList.remove('is-hidden');
        }

        this.updatePriceTotal((event.detail * this.quantityInput.value));
    }

    private updatePriceTotal(price: number): void {
        const convertedPrice = (price / 100);
        const priceWithDecimals = new Intl.NumberFormat(
            document.querySelector('html').getAttribute('lang'), {
                minimumFractionDigits: 2
            }).format(convertedPrice);

        this.querySelector(`span.${this.jsName}__price`).innerHTML = `${priceWithDecimals} ${this.currencySymbol}`;
    }

    private get currencySymbol(): string {
        return this.getAttribute('currency-symbol');
    }

    private get sku(): string {
        return this.getAttribute('sku');
    }

    private get priceInitial(): string {
        return this.getAttribute('price');
    }

    private get isInitiallyHidden(): boolean {
        return this.hasAttribute('is-initially-hidden');
    }
}
