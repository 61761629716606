import Component from 'ShopUi/models/component';
import GoogleTagManager from '../../../../../../GoogleTagManager/Theme/default/components/molecules/google-tag-manager';

export default class ProductItem extends Component {
    deleteItemButton: HTMLButtonElement
    quantityChangeDecrement: HTMLButtonElement
    quantityInput: HTMLInputElement
    quantityChangeIncrement: HTMLButtonElement

    constructor() {
        super();

        this.deleteItemButton = <HTMLButtonElement> this.querySelector(`.${this.jsName}__remove`);
        this.quantityInput = <HTMLInputElement> this.querySelector(`.js-quantity-counter__input`);
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    protected mapEvents(): void {
        if (this.deleteItemButton) {
            this.deleteItemButton.addEventListener('click', (event: Event) => this.onDeleteItemButtonClick(event));
        }

        if (this.quantityInput) {
            this.quantityInput.addEventListener('change', (event: Event) => this.onQuantityChange(event));
            this.quantityInput.addEventListener('keydown', (event: KeyboardEvent) => this.onQuantityKeydown(event));
        }
    }

    protected onDeleteItemButtonClick(event: Event) {
        this.trackRemoveProductFromCart(this.quantityValue, this.productPrice);
    }

    get productSku(): string {
        return this.getAttribute('data-sku');
    }

    get productName(): string {
        return this.getAttribute('data-name');
    }

    get productPrice(): number {
        let price = this.getAttribute('data-price-unit');

        return this.convertIntToFloat(price);
    }

    get quantityValue(): number {
        return parseInt(this.getAttribute('data-quantity'));
    }

    private trackAddProductToCart(quantity: number, price: number) {
        if (
            !this.productSku ||
            !this.productName ||
            !price ||
            !quantity
        )
            return;

        GoogleTagManager.addToCart({
            itemId: this.productSku,
            itemName: this.productName,
            unitPrice: price,
            quantity: quantity,
            currency: 'EUR'
        });
    }

    private trackRemoveProductFromCart(quantity: number, price: number) {
        if (
            !this.productSku ||
            !this.productName ||
            !price ||
            !quantity
        ) {
            return;
        }

        GoogleTagManager.removeFromCart({
            itemId: this.productSku,
            itemName: this.productName,
            unitPrice: price,
            quantity: quantity,
            currency: 'EUR'
        });
    }

    private onQuantityKeydown(event: KeyboardEvent) {
        const intRegExp = /\d/;

        if (
            (event.key.length > 1) || ((event.key === '-') &&
            // @ts-ignore
            (event.target.value.length === 0)) || intRegExp.test(event.key)
        ) {
            return;
        }

        event.preventDefault();
    }

    private onQuantityChange(event: Event) {
        // @ts-ignore
        if (event.target.value === '') {
            event.preventDefault();
            return;
        }

        this.trackRemoveProductFromCart(this.quantityValue, this.productPrice);

        // @ts-ignore
        this.trackAddProductToCart(parseInt(event.target.value), this.productPrice);
    }

    private convertIntToFloat(intString: string): number {
        return parseFloat(intString.slice(0, -2) + '.' + intString.slice(-2));
    }
}
