import Component from 'ShopUi/models/component';

export default class SmartProjectPriceRequest extends Component {
    private checkboxElement: HTMLSpanElement

    constructor() {
        super();

        this.checkboxElement = <HTMLButtonElement> this.querySelector(`.${this.jsName}__checkbox`);
    }

    protected readyCallback(): void {
        this.mapEvents();
    }

    private mapEvents(): void {
        this.checkboxElement.addEventListener('change', (event: Event) => this.onCheckboxClick(event));
    }

    private onCheckboxClick(event: Event) {
        // @ts-ignore
        event.target.form.submit();
    }
}
